import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { APPCONSTANTS } from 'src/app/constants/appconstants';
import { updateBannerRequest } from 'src/app/modals/bannerMsg/updateBannerRequest';
import { ProfileService } from 'src/app/services/profile/profile.service';

@Component({
  selector: 'app-bannermsg',
  templateUrl: './bannermsg.component.html',
  styleUrl: './bannermsg.component.css'
})



export class BannermsgComponent implements OnInit {

  @ViewChild("errorPopup", { static: true })
  errorPopupTemplateRef?: TemplateRef<HTMLElement>;
  
  constructor(private fb: FormBuilder, private profileService: ProfileService, private router: Router,private modalService: NgbModal) {

  }
  ngOnInit(): void {
    sessionStorage.setItem("lastPageBeforeReload", this.router.url);
    // this.displayBannerMessage();
  }
  show: boolean = false;
  bannerForm = this.fb.group({
    bannerMsgField: ['', [Validators.required]],
    bannerMsgbln: [false],
  });
  onMessageSubmit() {
    debugger;
    if (this.bannerForm.valid == true) {
      this.show = false;
      let bannerWebReq = new updateBannerRequest();
      bannerWebReq.messageId = 1;
      bannerWebReq.showMessage = this.bannerForm.get('bannerMsgbln')?.value ?? true;
      bannerWebReq.message = '' + this.bannerForm.get('bannerMsgField')?.value;
      this.profileService.postBannerMessage(bannerWebReq).then((response: any) => {
        console.log(response);
        if (response.status.statusCode.toLowerCase() == APPCONSTANTS.API_RESPONSE_SUCCESS_TXT) {
          this.goto('home');
        }
        else{
          this.modalService.open(this.errorPopupTemplateRef, { size: 'md',backdrop:'static'});
        }
      });
    }
    else if (this.bannerForm.invalid) {
      this.show = true;
    }
  }
  // displayBannerMessage() {
  //   this.profileService.getBannerMessage().then((response: any) => {
  //     console.log(response);
  //     if (response.message.showMessage == true) {
  //       this.bannerForm.get('bannerMsgbln')?.setValue(true);
  //       // this.bannerForm.get('bannerMsgField')?.setValue(response.message.message);
  //     }
  //   });
  // }
  goto(routeUrl: string) {
    this.router.navigateByUrl("/" + routeUrl);
  }
}
