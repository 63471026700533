import { Component, OnInit } from '@angular/core';
import { FormsModule, FormControl, FormGroup, FormBuilder, Validators, NgForm } from '@angular/forms';
import { cancelRegistrationRequest } from 'src/app/modals/registration/cancelRegistrationRequest';

import { Router } from '@angular/router';
import { RegistrationhelperService } from 'src/app/services/registrationhelper/registrationhelper.service';
import { cancelRegistrationResponse } from 'src/app/modals/registration/cancelRegistrationResponse';
import { SteponeLinkProvisionResponse } from 'src/app/modals/registration/SteponeLinkProvisionResponse';
import { APPCONSTANTS, BOOLEAN_FLAG } from 'src/app/constants/appconstants';

import { SteponeLinkProvisionRequest } from 'src/app/modals/registration/SteponeLinkProvisionRequest';
import { StepthreeInitiateFaxRequest } from 'src/app/modals/registration/StepthreeInitiateFaxRequest';

import { StepthreeInitiateFaxResponse } from 'src/app/modals/registration/StepthreeInitiateFaxResponse';
import { rightFaxPinVerificationRequest } from 'src/app/modals/registration/rightFaxPinVerificationRequest';
import { ValidateUuidServiceRequest } from 'src/app/modals/registration/ValidateUuidServiceRequest';
import { ValidateUuidServicePrescriberResponse } from 'src/app/modals/registration/ValidateUuidServicePrescriberResponse';
import { CONTEXT_PATH } from 'config/stage';
import { registrationService } from 'src/app/services/registrationSteps/registration.service';
import { environment } from 'src/environments/environment';



@Component({
  selector: 'app-registerstepthree',
  templateUrl: './registerstepthree.component.html',
  styleUrls: ['./registerstepthree.component.css']
})
export class RegisterstepthreeComponent implements OnInit {
  constructor(private fb: FormBuilder, private regisrtationService: registrationService, private router: Router, private registrationHelper: RegistrationhelperService) { }
  ngOnInit(): void {
    var temp = sessionStorage.getItem('loginuserdata');
    if (temp != undefined || temp != null) {

      this.loginUserData = JSON.parse(temp);
    }
    if (this.loginUserData.reActivateInd == BOOLEAN_FLAG.YES) {
      this.userDeactivated = true;
    }
    this.newSession();
  }
  userDeactivated: boolean = false;
  loginUserData!: any;

  faxNumber: any = '';
  newPinSent: boolean = false;

  newSessionLayout: boolean = false;
  oldSessionLayout: boolean = true;

  newSession() {
    let faxDetails: string | null = sessionStorage.getItem('linkProvisionResponse');
    if (faxDetails == null) {
      this.newSessionLayout = true;
      this.oldSessionLayout = false;
      //if user was deactivated
      if (sessionStorage.getItem('pinverified') != undefined || sessionStorage.getItem('pinverified') != null) {
        //check if the user is deactivated and the state is in verfied pin state
        if (this.userDeactivated == true) {
          this.onCancelRegistration();
          this.onCancelRegistration();

        } 
        else {
          this.registrationComplete = true;
        }

      }
      //unregistered user
      else {
        var apiParamters = new SteponeLinkProvisionRequest();
        let tempobject = sessionStorage.getItem('selfregistration');
        apiParamters.presbrId = '';
        apiParamters.reActivateInd = BOOLEAN_FLAG.NO;
        if (tempobject != null) {
          const selfregistrationdetails = JSON.parse(tempobject);
          apiParamters.provisioningRequestId = selfregistrationdetails.currentpage.linReqId;
          //for cases where provisioned user ids are deactivated or no proper data is found
          if (apiParamters.provisioningRequestId == undefined) {
            sessionStorage.removeItem('loginuserdata');
            this.router.navigateByUrl('/error');
          }
          else {
            this.regisrtationService.postLinkProvision(apiParamters).then((data: SteponeLinkProvisionResponse) => {
              if (data.status.statusCode == 'Success') {
                let prevDataCheck1 = sessionStorage.getItem('linkProvisionResponse');
                if (prevDataCheck1 != null) {
                  sessionStorage.removeItem('linkProvisionResponse');
                }
                sessionStorage.setItem('linkProvisionResponse', JSON.stringify(data));
                this.displayfax();
              }
            });
          }
        }
      }

    }
    else {
      this.displayfax();
    }
  }

  displayfax() {
    let temp: string | null = sessionStorage.getItem('linkProvisionResponse');
    if (temp != null) {
      let temp1 = JSON.parse(temp);
      this.faxNumber = temp1.faxNumber;
      this.faxNumber = '(' + this.faxNumber.slice(0, 3) + ')' + ' ' + this.faxNumber.slice(3, 6) + '-' + this.faxNumber.slice(6);
    }
  }


  //popup code
  showPopup: boolean = false;
  showErrorPopup: boolean = false;

  openPopup() {
    this.showPopup = true;
  }
  closePopup() {
    this.showPopup = false;
  }
  openErrorPopup() {
    this.showErrorPopup = true;
  }
  closeErrorPopup() {
    this.showErrorPopup = false;
  }
  onYesClick() {
    //write the code
    this.closePopup();
    this.onCancelRegistration();
  }
  onNoClick() {
    //write the code
    this.closePopup();
  }
  PIN_Form = this.fb.group({
    PIN_Field: ['', [Validators.required, Validators.pattern('[0-9]*$')]]
  });

  //check if the fax form validations are correct
  isFormValid(): boolean {
    return this.PIN_Form.valid;
  }


  onCancelRegistration() {
    var cancelRequest1 = new cancelRegistrationRequest();
    let tempobject = sessionStorage.getItem('selfregistration');
    if (tempobject != null) {
      const userInfoSessionObject = JSON.parse(tempobject);
      cancelRequest1.optumID = userInfoSessionObject.ohid;
      // cancelRequest2.optumID=userInfoSessionObject.ohid;
      cancelRequest1.uuid = userInfoSessionObject.uuid;
      if (this.userDeactivated == true) {
        cancelRequest1.linkRequestId = '0';
      }
      else {
        cancelRequest1.linkRequestId = userInfoSessionObject.currentpage.linReqId;
      }


    }

    cancelRequest1.isRegistraionLinkCancel = true;
    cancelRequest1.pcmsStatusCode = '5'
    cancelRequest1.linkStatusCode = '5';
    console.log(cancelRequest1);

    this.regisrtationService.postCancelRegistration(cancelRequest1).then((data: cancelRegistrationResponse) => {
      if (data.status.statusCode.toLowerCase() == APPCONSTANTS.API_RESPONSE_SUCCESS_TXT) {
        console.log('registration in step 2 cancelled');
        const cancelReg = sessionStorage.getItem('cancelReg');
        if (cancelReg == null) {
          sessionStorage.setItem('cancelReg', 'true');
        }
        sessionStorage.removeItem('userRole');
        sessionStorage.removeItem('faxDetails');
        this.router.navigateByUrl('/register/stepone');
        this.registrationHelper.getRegistrationStatus();
      }
      else {
        this.openErrorPopup();

      }
    });

  }

  newPinSentPopup() {
    this.newPinSent = true;
  }

  onOkClick() {
    this.newPinSent = false;
  }

  exceeded: boolean = false;
  show: boolean = true;
  showExceeded() {
    this.exceeded = true;
  }
  showNotexceeded() {
    this.exceeded = false;
  }
  insideTheBox() {
    this.show = false;
  }
  outsideTheBox() {
    this.show = true;
  }

  exceededOtpAttempt: boolean = false;
  showExceededOtpAttempt() {
    this.exceededOtpAttempt = true;
  }
  showNotExceededOtpAttempt() {
    this.exceededOtpAttempt = false;
    this.showNotInvalidPin();
  }
  invalidOtp: boolean = false;
  showInvalidPin() {
    this.invalidOtp = true;
  }
  showNotInvalidPin() {
    this.invalidOtp = false;
  }

  // invalidInput:boolean=false;

  // checkInvalidInput(value:any){
  //   debugger;
  //   const regex=/[0-9]*$/;
  //   if(regex.test(value?.PIN_Field)){
  //     this.invalidInput=false;
  //   }
  //   else{
  //     this.invalidInput=true;
  //   }
  // }

  changeInInput(value: any) {
    if (this.PIN_Form.invalid || value?.PIN_Field == '') {
      this.invalidOtp = false;
    }
    if (this.PIN_Form.valid && value?.PIN_Field != '') {
      this.exceeded = false;
    }
  }
  registrationComplete: boolean = false;
  showRegistrationComplete() {
    this.registrationComplete = true;
  }
  // goHomePage(){

  //   // var apiParamters = new ValidateUuidServiceRequest()
  //   // var apiResponse = new ValidateUuidServicePrescriberResponse();
  //   // this.stepthreeService.postValidateUUID(apiParamters).subscribe((apiResponse:any)=>{
  //   //   if(apiResponse.status.statusCode=='Success')
  //   //   {
  //   //     this.registrationComplete=false;
  //   //     this.router.navigateByUrl("/home");        
  //   //   }
  //   //   else{
  //   //     this.showErrorPopup
  //   //   }           
  //   // });
  //   debugger;
  //   this.router.navigateByUrl('/home');


  // }
  gotoDashboard() {
    window.location.href = '/';
    sessionStorage.removeItem("lastPageBeforeReload");
    // this.router.navigateByUrl("/");

  }

  onResendPin(requestPinFlag: number) {
    var apiParamters = new StepthreeInitiateFaxRequest();
    let tempobject = sessionStorage.getItem('selfregistration');
    if (tempobject != null) {
      const selfregistrationdetails = JSON.parse(tempobject);
      apiParamters.optumId = selfregistrationdetails.ohid;
      apiParamters.requestPinFlag = requestPinFlag;

      this.regisrtationService.postInitiateFax(apiParamters).then((data: StepthreeInitiateFaxResponse) => {
        if (data.status.statusCode == 'Success') {
          this.newPinSentPopup();
          this.showNotexceeded();

        }
        else if (data.status.statusDesc == 'PIN Retry attempts exceed for this user' && requestPinFlag == 2) {
          this.showReturntoMarketPopup()
        }
        else if (data.status.statusDesc == 'PIN Retry attempts exceed for this user' && requestPinFlag == 1) {
          this.showExceeded();

        }
        else if (data.status.statusDesc == 'PIN has been expired') {
          this.showExceeded();

        }
        else if (data.status.statusDesc == 'Invalid user state') {
          this.showErrorPopup;
        }
        else {
          this.showErrorPopup;
        }
      });
    }
    else {
      this.showErrorPopup;
    }

  }

  returnMarketPopopup: boolean = false;
  showReturntoMarketPopup() {
    this.returnMarketPopopup = true
  }
  onClickReturnToMarket() {
    this.returnMarketPopopup = false;
    this.onCancelRegistration();
  }

  onSubmitPIN() {

    var apiParamters = new rightFaxPinVerificationRequest();

    let tempobject = sessionStorage.getItem('selfregistration');
    if (tempobject != null) {
      const selfregistrationdetails = JSON.parse(tempobject);
      apiParamters.uuid = selfregistrationdetails.uuid;
      apiParamters.linkRequestId = selfregistrationdetails.currentpage.linReqId;

      apiParamters.reActivateInd = BOOLEAN_FLAG.NO;
      var x = '' + this.PIN_Form.value.PIN_Field;
      apiParamters.rightFaxPin = parseInt(x);

      this.regisrtationService.postRightFaxPinVerification(apiParamters).then((data: any) => {
        if (data.errorMessage == 'AttemptsExceeded') {
          this.showExceededOtpAttempt();
        }
        else if (data.errorMessage == 'InvalidPIN') {
          this.showInvalidPin();
        }
        else if (data.errorMessage == 'Optum provisioning Failure') {
          this.openErrorPopup();
        }
        else if (data.statusCode == 'Success') {
          this.showRegistrationComplete();
        }
        else {
          this.openErrorPopup();
        }

      });

    }
  }

  goToPrivacyLink() {
    // this.router.navigateByUrl(url);
    window.open(environment.privacyurl, "_blank");
  }
  goToTermsLink() {
    // this.router.navigateByUrl(url);
    window.open(environment.termsurl, "_blank");
  }
  goToContactusLink() {
    // this.router.navigateByUrl(url);
    window.open(environment.contactusurl, "_blank");
  }


}
