<section id="header" class="d-print-none">
    <nav class="navbar navbar-default navbar-fixed-top">
        <div class="container-fluid bg-white">
            <div class="row">
                <div class="col-2 user_menu">
                    <globalmenu></globalmenu>
                    <!-- <a class="navbar-brand" href="javascript:void(0)">
                        <img ng-if="blnsmapuser" id="logo_id" alt="" class="img logo_img" src="./../../../assets/images/menu.png">
                    </a> -->
                </div>
                <div class="col-8">
                    <breadcrumb></breadcrumb>
                </div>
            </div>
        </div>
    </nav>    
</section>
<section id="content">
    <router-outlet></router-outlet>
</section>
<section id="footer" class="d-print-none">
    <nav class="navbar navbar-expand-lg bg-light">
        <div class="container-fluid nav-footer text-center">
          <div class="row">
              <div class="col">
                  <ul>
                  <li  aria-label="2024 Optum, Inc. All rights reserved">&#169; 2024 Optum, Inc. All rights reserved</li>
                  &nbsp;|&nbsp;
                  <li><a class="btn invisiblebtn btn-link " aria-label="Link to Privacy Policy" (click)="goToPrivacyLink()">Privacy Policy</a></li>
                  &nbsp;|&nbsp;
                  <li><a  class="btn invisiblebtn btn-link " aria-label="Link to Terms of Use" (click)="goToTermsLink()">Terms of Use</a></li>
                  &nbsp;|&nbsp;
                  <li><a class="btn invisiblebtn btn-link " aria-label="Link to Contact Us" (click)="goToContactusLink()">Contact Us</a></li>
                  </ul>
              </div>
          </div>
        </div>
      </nav>
</section>


<!-- Modal Structure -->
<div id="termsModal" class="modal" *ngIf="showModal">
    <div class="modal-content">
      <span class="h4" style="color:#f5b700;">Warning!</span>
      <p>This system contains U.S Government information.  By using this information system, you are consenting to system monitoring for law enforcement and other purposes.  Unauthorized or improper use of, or access to, this computer system may subject you to state and federal criminal prosecution and penalties as well as civil penalties.  At any time, the government may intercept, search, and seize any communication or data transiting or stored on this information system.</p>
      
      <div class="col-12" style="width: 100%; text-align: center; padding: 10px;">
        <button (click)="acknowledge()" class="btn btn-primary">Acknowledge</button>
      </div>
    </div>
    <div class="col-12" style="width: 100%; text-align: center; padding: 10px;">
        <button (click)="acknowledge()" class="btn btn-primary">Acknowledge</button>
      </div>
  </div>
<!-- End of  -->