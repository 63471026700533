module.exports = {
    CONTEXT_PATH: '/',
    PORT: '8080',
    SSO_AUTHURL: 'https\://identity.nonprod.onehealthcareid.com/oidc/authorize',
    SSO_TOKENURL:'https\://identity.nonprod.onehealthcareid.com/oidc/token',
    SSO_CLIENTID:'PCMS101147',
    SSO_CLIENT_SECRET:"2b0cc602b48f9600451c96c1c0cbabd4052d5c3d76120c356090697c9ed6a16b00c2ecc37105206292349d921fe820d8",
    SSO_SCOPE:'openid profile email',
    SSO_STATE:'PreCheckMyScript',
    SSO_RESPONSETYPE:'code',
    BECKEND_HOST:'https://pcms-services-stage.optum.com/',
    PROVISIONING_BECKEND_HOST:'https://pcms-provision-services-hcc-stage.optum.com/',

}