import {CONTEXT_PATH, SSO_AUTHURL, SSO_CLIENTID, SSO_RESPONSETYPE, SSO_SCOPE, SSO_STATE} from 'config/stage';

const redirect_uri = window.location.origin+CONTEXT_PATH;

export const environment = {
  production:false,
  sso_contextpath:CONTEXT_PATH,
  sso_authurl: SSO_AUTHURL,
  sso_clientId: SSO_CLIENTID,
  sso_scope:SSO_SCOPE,
  sso_redirectUri: redirect_uri,
  sso_state: SSO_STATE,
  sso_responseType:SSO_RESPONSETYPE,
  membersearchIncludeLob: true,
  lob:"UHCEI",
  instanceId:"BOOK2",
  paginationRecordsPerPage:10,
  rXClaimInstanceId:"BOOK2",
  EPA_POOLING_INTERVAL:10,
  EPA_WAITING_TIME_SEC:45,


  ohid_proviosioning:["TinaStage001","praveenkumar_j","looper54","jvent607stg","rshekharAdmin","jcarpenter1011"],
  ohid_controlpanel: ["TinaStage001", "praveenkumar_j", "looper54", "jvent607stg", "rshekharAdmin", "jcarpenter1011", "kanjani","UserEliStage","Tanxx364","v146@nonprod"],
  ohid_bannerpanel: ["TinaStage001", "jvent607stg", "kanjani","UserEliStage","Tanxx364","v146@nonprod","kmadaiah21"],

  proxyurl: redirect_uri+"api",
  provisoningproxyurl: redirect_uri+"api2",
  // proxygeturl:redirect_uri+"api/presbook",
  loginProxyurl:redirect_uri+"api/login",
  logoutProxyurl:redirect_uri+"api/logout",
  proxyurlFileUpload:window.location.origin+CONTEXT_PATH+"api/upload",
  proxyurlProvisioningFileUpload:window.location.origin+CONTEXT_PATH+"api2/upload",
  loginUrl: "rest/providerLogin/validateUUID?uuid={uuid}",
  registration_status_url: 'rest/registration/regOptumIdStatus?optumId={optumId}',
  eventlogUrl: "rest/auditTracking/applEventLog",

  memberSearchUrl: "rest/memberSearch/getMemberSearchDetails",
  // getMemberDetailsUrl: "rest/memberSearch/getMemberDetails",
  getMemberDetailshemiUrl: "rest/memberSearch/getMemberDetailshemi",
  favouriteMembersUrl: "rest/memberSearch/getFavoriteMemberList?presbrId={presbrId}",
  addFavouriteMemberUrl: "rest/memberSearch/saveMemberFavorite",
  removeFavouriteMemberUrl: "rest/memberSearch/removeFavoriteMember?mbrSearchId={mbrSearchId}",
  checkMmdPartDMemberUrl: "rest/memberSearch/checkMmdPartDMember",
  getDrugFavoritesUrl: "rest/memberSearch/getDrugFavorites?presbrId={presbrId}&loggedInUuid={UUID}",
  preferredPharmacyUrl: "rest/memberSearch/getPreferredPharmacyDetails",
  getDrugSearchDetailsUrl: "rest/memberSearch/getDrugSearchDetails",
  diagnosisCodeSearchUrl: "rest/diagnosis/diagnosisSearch",
  diagnosisDescriptionSearchUrl: "rest/diagnosis/diagnosisSearch",
  // getPharmacySearchDetailsUrl: "rest/memberSearch/getPharmacySearchDetails",
  getPharmacySearchDetailshemiUrl: "rest/memberSearch/getPharmacySearchDetailshemi",
  unFavouriteDrugUrl: "rest/memberSearch/favOrUnfavDrug",
  favouriteDrugUrl: "rest/memberSearch/favOrUnfavDrug",
  getRecentPresbrDtlsUrl: "rest/registration/getRecentPresbrDtls",
  prescriberSearchUrl: "rest/prescriber/getPrescriberSearch",
  prescriberDetailUrl: "rest/prescriber/getPrescriberDetails",
  addFavouritePrescriberUrl: "rest/memberSearch/savePrescriberFavorite",
  removeFavouritePrescriberUrl: "rest/memberSearch/removeFavoritePrescriber?presbrFavId={presbrFavId}",
  favouritePrescribersUrl: "rest/memberSearch/getFavoritePrescriberList?presbrId={presbrId}&loggedInUuid={UUID}",
  getProvisioningUploadHistoryUrl: "rest/userProvision/uploadHistory?presbrId={presbrId}",
  generatePARefIdUrl: "rest/rxLinkPASTransactions/getPAReferenceID?uuid={UUID}",
  submitPAInitiationRequest: "rest/rxLinkPASTransactions/paInitiationRequest",
  paCancellationUrl: "rest/rxLinkPASTransactions/paCancelRequest",
  poolForPAInitiationResponseUrl: "rest/rxLinkPASTransactions/sendPAInitiationResponseJsonDB",
  poolForPACancellationResponseUrl: "rest/rxLinkPASTransactions/sendPACancelResponsseJson",
  saveForLaterUrl: "rest/rxLinkPASTransactions/paRequestSubmission",
  getSavedPADetailsUrl: "rest/rxLinkPASTransactions/getSavedPaRequest?paReferenceID={parefId}&uuid={uuid}",
  getNtfsSavedPADetailsUrl: "rest/rxLinkPASTransactions/getSavedPaRequest?paReferenceID={parefId}&uuid={uuid}&notificationId={notificationId}",
  getResubmitSavedPADetailsUrl: "rest/rxLinkPASTransactions/getPainitiationReSubmitDetails?paReferenceID={parefId}",
  submitOrSavePARequestUrl: "rest/rxLinkPASTransactions/paRequestSubmission",
  viewQuestionSetUrl: "rest/rxLinkPASTransactions/viewQuestionSet?paCaseID={paCaseID}",
  poolForPAResponseUrl: "rest/rxLinkPASTransactions/sendPAResponseJson",
  uploadFileUrl: "rest/rxLinkPASTransactions/paRequestAttachment?paReferenceID={parefId}&fileName={file}",
  downloadFileUrl: "rest/rxLinkPASTransactions/getAttachmentAsBytes?paAttachmentID={paAttachId}",
  faxForm: "https://professionals.optumrx.com/prior-authorization.html#faxform",
  getTrailClaimAdjudicationDetailsURL: "rest/trailClaimAdjudication/getTrailClaimAdjudicationDetails",
  getAdrBkPrescribersUrl: "rest/registration/getAdrBkPrescribers?adminPresbrId={adminPresbrId}",
  changeDefaultprescriberUrl: "rest/registration/changeDefaultPrescriberForAdmin",
  addPrescribersUrl: "rest/registration/addPrescribers",
  getNotificationsUrl: "rest/rxLinkPASTransactions/getNotifications",
  getRecentPrescriberDetailsUrl: "rest/registration/getRecentPresbrDtls",
  getUpdateNotficationStatusUrl: "rest/rxLinkPASTransactions/updateNtfyStatus",
  getDeletedNotificationsUrl: "rest/rxLinkPASTransactions/getDeletedNtfys",

  postInitiateFaxUrl: "rest/registration/initiateFax",
  rightFaxPinVerificationUrl: "rest/registration/OTPVerification",
  registrationInitiationUrl: "rest/registration/registrationinitiation",
  linkProvisionDetailsUrl: "rest/linkProvisionService/getLinkProUserDetails",
  getPharmacyDetailsUrl: "rest/memberSearch/getPharmacyDetails",
  cancelRegistrationUrl: "rest/registration/cancelRegistration",
  updateStatusUrl: "rest/registration/updateStatus",
  registerUserUrl: "rest/registration/registerUser",
  getRTBDetailsUrl: "rest/EMRServices/getRTBDetails",
  downloadRTBRegressionSheetUrl: "rest/EMRServices/downloadRTBRegressionSheet",
  RTBRegressionUploadUrl: "rest/EMRServices/RTBRegressionUpload&presbrId={presbrId}&fileName={file}",

  downloadExcelTemplateUrl: "rest/userProvision/downloadUserProvisionSheet",
  provisioningFileUploadUrl: "rest/userProvision/userProvisionAttachment?presbrId={presbrId}&fileName={file}",
  submitUserProvisionListUrl: "rest/userProvision/submitUserProvisionList",
  getReviewProvisionUrl: "rest/userProvision/reviewProvision?provisionId={provisionId}",
  updateProvisionStatusUrl: "rest/userProvision/updateProvisionUser",
  optumIdSearchQueryUserUrl: "rest/queryUser/optumIdSearchQueryUser?optumID={optumID}",
  memberNotificationsUrl: "rest/rxLinkPASTransactions/getMemberNotifications",
  optumIdSearch: "rest/prescriberControl/optumIdSearch?optumID={optumID}",
  updatePresbrDetailsUrl: "rest/prescriberControl/updatePresbrDetails",
  reactiveAllPrescribersUrl: 'rest/registration/reactiveAllPrescribers',
  verifyProviderUrl: 'https://precheckmyscript-stage.uhcprovider.com/#/verifyProvider',
  privacyurl: 'https://precheckmyscript-stage.uhcprovider.com/#/privacy',
  termsurl:'https://precheckmyscript-stage.uhcprovider.com/#/terms',
  contactusurl:'https://precheckmyscript-stage.uhcprovider.com/#/contactus',
  updatePresbrAdbkStatusUrl: "rest/registration/updatePresbrAdbkStatus",
  getSupportStaffUrl: "rest/registration/presbrSuppStaff",
  changeDefaultAddressUrl: "rest/registration/changeDefaultAddress",
  changeAdminStatus: "rest/registration/changeAdminStatus",
  approveOrDenyPrescriberUrl: "rest/registration/approveOrDenyPrescriber",
  savedMemberPAHistoryUrl: "rest/rxLinkPASTransactions/getMemberPASavedHistory",
  dashboardNotificationsMethod: "rest/rxLinkPASTransactions/getNotifications",
  submittedPAHistoryUrl: "rest/rxLinkPASTransactions/getProviderPAHistory",
  submittedMemberPAHistoryUrl: "rest/rxLinkPASTransactions/getMemberPAHistoryService",
  savedPAHistoryUrl: "rest/rxLinkPASTransactions/getProviderPASavedHistory",
  // Remove the duplicate property "memberNotificationsUrl"
  // memberNotificationsUrl:"rest/rxLinkPASTransactions/getMemberNotifications",
  logoutUrl: "https://identity.nonprod.onehealthcareid.com/oidc/logout?id_token_hint={previously_issued_jwt_id_token}&post_logout_redirect_uri={redirect_uri}&state={some_random_string}",
  // logout_redirect_uri:"https://precheckmyscript-stage.uhcprovider.com/",
  logout_random_string:"PreCheckMyScript",
  bannerMessageUrl: "rest/bannerMessage/getMessage",
  updateBannerMessageUrl: "rest/bannerMessage/updateMessage",
};

